import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    NavItem,
    NavLink,
    Button,
} from 'reactstrap';
import classnames from 'classnames';
import { useStores } from '../hooks/use-stores';

function UserMenu({ switchLanguage, sendFeedback, handleSignout }) {
    const [userMenuVisible, setUserMenuVisible] = useState(false);
    const [languageMenuVisible, setLanguageMenuVisible] = useState(false);

    const { t } = useTranslation();
    const { store, brandingStore, authStore, companyStore, commonStore } =
        useStores();

    const { companies, currentCompany } = companyStore;
    const { isAuthenticated, currentUser } = authStore;
    const { effectiveLanguages: languages } = commonStore;
    const { lmsDomain } = brandingStore;

    const language = store.language;

    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        return name.split(' ').map((n) => n[0]);
    };

    const currentUserInitials = currentUser
        ? getInitials(currentUser.name)
        : '';

    const feedbackEnabled =
        currentCompany &&
        currentCompany.settings['ui.feedback_enabled'] === '1';

    const toggleUserMenu = () => {
        const currentState = userMenuVisible;
        setUserMenuVisible(!currentState);
        setLanguageMenuVisible(false);
    };

    const toggleLanguageMenu = () => {
        const currentState = languageMenuVisible;
        setUserMenuVisible(false);
        setLanguageMenuVisible(!currentState);
    };

    const onChangeCompany = (company_id) => {
        companyStore.setCurrentCompany(company_id);
        toggleUserMenu();
    };

    const changeLanguage = (language_two_letter_code) => {
        if (store) {
            if (switchLanguage) {
                switchLanguage(language_two_letter_code);
            }
            toggleLanguageMenu();
        }
    };

    if (isAuthenticated) {
        return (
            <Nav>
                <NavItem className="dropdown">
                    <Dropdown
                        className="header-dropdown header-user"
                        isOpen={userMenuVisible}
                        toggle={toggleUserMenu}
                    >
                        <DropdownToggle
                            tag="div"
                            className="header-name"
                            onClick={toggleUserMenu}
                        >
                            {currentUser.name || currentUser.email}
                            {currentCompany && (
                                <div className="company">
                                    {currentCompany.company_name}
                                </div>
                            )}
                            <span>{currentUserInitials}</span>
                        </DropdownToggle>

                        <DropdownMenu
                            tag={'ul'}
                            className={'nav-dropdown company-list'}
                        >
                            {store && store.company && (
                                <NavItem>
                                    <div className="header-subtext">
                                        {store.company.company_name}
                                    </div>{' '}
                                </NavItem>
                            )}
                            {companies && companies.length > 0 && (
                                <Fragment>
                                    {companies.map((comp) => (
                                        <NavItem
                                            key={comp.company_id}
                                            className={classnames({
                                                selected:
                                                    currentCompany &&
                                                    currentCompany.company_id &&
                                                    currentCompany.company_id ===
                                                        comp.company_id,
                                            })}
                                            onClick={() =>
                                                onChangeCompany(comp.company_id)
                                            }
                                        >
                                            {comp.company_name}
                                        </NavItem>
                                    ))}
                                    <hr />
                                </Fragment>
                            )}

                            {languages && languages.length > 1 && (<div className="language">
                                {languages.map((x) => {
                                    return (
                                        <NavLink
                                            key={x.code}
                                            className={
                                                language === x.code
                                                    ? 'link selected'
                                                    : 'link'
                                            }
                                            onClick={() =>
                                                changeLanguage(x.code)
                                            }
                                        >
                                            <img
                                                src={x.flag_image}
                                                alt={t('Choose language')}
                                            />
                                            {x.name}
                                        </NavLink>
                                    );
                                })}
                            </div>)}

                            {store && companyStore.haveAdminRights && (
                                <>
                                    <NavItem>
                                        <a href={lmsDomain}>
                                            {t('Training manager')}
                                        </a>
                                    </NavItem>
                                </>
                            )}
                            {feedbackEnabled && (
                                <>
                                    <Button
                                        type="submit"
                                        className="nav-link"
                                        color="secondary"
                                        onClick={sendFeedback}
                                    >
                                        {t('Send Feedback')}
                                    </Button>
                                    <hr />
                                </>
                            )}

                            <Button
                                type="submit"
                                className="nav-link"
                                color="secondary"
                                onClick={handleSignout}
                            >
                                {t('Sign out')}
                            </Button>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>

                {store && (
                    <div
                        className={
                            'done-animation ' +
                            (store.showSubjectDoneAnimation ? 'play' : '')
                        }
                    >
                        <img
                            src="/static/img/icon-medal.png"
                            alt={t('Completed subjects')}
                        />
                        <div className="title">{t('Great!')} </div>
                        <div className="text">{t('Subject completed')}</div>
                    </div>
                )}
            </Nav>
        );
    } else {
        return (
            <Nav>
                <NavItem>
                    {/* @TODO Add support for passing current URL path as redirect URL so that users without JavaScript are also
          redirected to the page they were on before they signed in. */}
                    {/*<a href="/auth/signin" className="btn btn-primary">Sign in</a>*/}
                </NavItem>
            </Nav>
        );
    }
}

export default observer(UserMenu);
