import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { awareGoTheme } from '../theme';
import { extractErrorMessage, querystring } from '../utils/helpers';

class CompanyStore {
    companyId =
        parseInt(querystring('o', window.location.search)) ||
        parseInt(window.localStorage.getItem('companyId'));
    companies = [];
    loadingCompanies = null;
    noCompanies = false;
    haveAdminRights = false;
    currentCompany = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    logout = () => {
        this.companies.replace([]);
        this.currentCompany = null;
        this.companyId = null;
        this.haveAdminRights = false;
        this.loadingCompanies = false;
        window.localStorage.removeItem('companyId');
    };

    async loadCompanies(isGuest) {
        this.loadingCompanies = true;
        this.error = null;
        this.companies.clear();
        if (isGuest) {
            this.noCompanies = this.companies.length === 0;
            this.loadingCompanies = false;
            return;
        }
        try {
            const result = await services.Companies.myCompanies();
            runInAction(() => {
                this.companies.replace(result.companies);
                this.noCompanies = this.companies.length === 0;
                if (this.companies.length > 0) {
                    let companyIdFromUrl = parseInt(
                        querystring('o', window.location.search)
                    );
                    if (companyIdFromUrl) {
                        if (
                            !this.companies.find(
                                (x) => x.company_id === companyIdFromUrl
                            )
                        )
                            companyIdFromUrl = null;
                    }
                    this.setCurrentCompany(
                        this.companyId ||
                            companyIdFromUrl ||
                            this.companies[0].company_id
                    );
                }
                this.haveAdminRights = result.haveAdminRights;
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.noCompanies = this.companies.length === 0;
                this.loadingCompanies = false;
            });
        }
    }

    setCurrentCompany(company_id) {
        company_id = parseInt(company_id);
        let company = this.companies.find((x) => x.company_id === company_id);
        if (!company && this.companies.length > 0) company = this.companies[0];
        if (company) {
            this.currentCompany = company;
            this.companyId = company.company_id;
            window.localStorage.setItem('companyId', company.company_id);
        }
    }

    get theme() {
        return {
            primary: this.currentCompany
                ? this.currentCompany.color_main
                    ? this.currentCompany.color_main
                    : awareGoTheme.primary
                : awareGoTheme.primary,
        };
    }
}

export default CompanyStore;
