import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import pluralize from 'pluralize';
import { useStores } from '../../hooks/use-stores';
import classnames from 'classnames';
import Question from '../Question';
import QuizIntro from '../../components/assessment/intro';
import Countdown from 'react-countdown';
import ErrorMessage from '../../components/errormessage';
import Review from '../../components/assessment/AssessmentFinalReview/Review';
import { pick } from 'lodash';
import { isMobile, getUA } from 'react-device-detect';
import '@awarego/awarego-components/lib/index.css';
import { Trans } from 'react-i18next';
import {
    FlexDiv,
    Heading2,
    palette,
    TextTiny,
    TextDefault,
    Heading3,
    Heading4,
    TextLabel,
} from '@awarego/awarego-components';
import OneMomentSpinner from '../../components/OneMomentSpinner';
import ViewHeader from '../../components/ViewHeader';
import { Block as BlockIcon } from '@material-ui/icons';
import AssessmentsViewFooter from '../../components/assessment/AssessmentsView/AssessmentsViewFooter';
import LanguageSelector from '../Subjects/language-selector';

function filterBlockValues(blockValues) {
    let result = {};
    map(blockValues, (v, k) => {
        if (v != null && k !== null && k !== 'undefined') result[k] = v;
    });
    return result;
}

export default observer(
    ({
        companyId,
        parentUrl,
        assessmentId,
        language,
        assessmentStore,
        questionStore,
        currentUser,
        onClose,
        redirectOnError = true,
        isDemo,
        isInfosec,
        restartDemo,
        isScorm,
        availableLanguages,
        handleOnQuestionComplete,
        handleOnFinish,
        onShowResults,
    }) => {
        const {
            trainingStore,
            store,
            commonStore,
            brandingStore,
            demoStore,
            authStore,
        } = useStores();
        const {
            error: assessmentError,
            loadingData,
            assessment,
            loadingAssessmentId,
            isStarted,
            done,
            isFinished,
            isExpired,
            isTimedOut,
            _questionAction,
        } = assessmentStore;
        const { brandingLoaded, account_name, logo } = brandingStore;
        const history = useHistory();
        const { t } = useTranslation();
        const [isNextAvailable, setIsNextAvailable] = useState(false);
        const [questionActions, setQuestionActions] = useState(null);
        const [questionId, setQuestionId] = useState(null);
        const {
            error: questionError,
            question,
            loadingData: loadingQuestionData,
        } = questionStore;
        const [blockNum, setBlockNum] = useState(-1);
        const [blockValues, setBlockValues] = useState(null);
        const [completedBlocks, setCompletedBlocks] = useState(null);
        const [questionComplete, setQuestionComplete] = useState(false);
        const [blockTransition, setBlockTransition] = useState(false);
        const [blockFadeIn, setBlockFadeIn] = useState(false);
        const [blockFadeOut, setBlockFadeOut] = useState(false);
        const [transitionTimer, setTransitionTimer] = useState(null);
        const [visibleBlocks, setVisibleBlocks] = useState(null);
        const [locationKeys, setLocationKeys] = useState([]);

        const [selectedLanguage, setSelectedLanguage] = useState(
            store.language
        );

        useEffect(() => {
            setSelectedLanguage(store.language);
        }, [store.language]);

        const switchLanguage = async (language_code) => {
            await store.setLanguage(language_code, true);
            setSelectedLanguage(language_code);
        };

        useEffect(() => {
            return history.listen((location) => {
                if (history.action === 'PUSH') {
                    setLocationKeys([location.key]);
                }
                if (history.action === 'POP') {
                    if (locationKeys[1] === location.key) {
                        setLocationKeys(([...keys]) => keys);
                    } else {
                        setLocationKeys((keys) => [location.key, ...keys]);
                        closeAssessment();
                    }
                }
            });
        }, [closeAssessment, history, locationKeys]);

        useEffect(() => {
            (async () => {
                if (blockTransition) {
                    await onNext(true, false);
                }
                setBlockFadeIn(true);
                setBlockFadeOut(false);
            })();
        }, [blockTransition, onNext]);

        useEffect(() => {}, [blockFadeIn]);

        useEffect(() => {}, [blockFadeOut]);

        useEffect(() => {
            if (blockNum !== -1 && questionId) {
                window.localStorage.setItem(
                    `block-num-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`,
                    blockNum.toString()
                );
            }
        }, [assessmentId, blockNum, currentUser.id, questionId]);

        useEffect(() => {
            if (questionActions && questionId) {
                window.localStorage.setItem(
                    `question-actions-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`,
                    JSON.stringify(questionActions)
                );
            }
        }, [assessmentId, currentUser.id, questionActions, questionId]);

        useEffect(() => {
            if (blockValues && questionId) {
                window.localStorage.setItem(
                    `block-values-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`,
                    JSON.stringify(blockValues)
                );
            }
            if (blockValues && question) {
                let filteredValues = filterBlockValues(blockValues);

                let visibleBlocks = question.ui.blocks.filter((block) => {
                    if (block.condition) {
                        if (blockValues[block.condition.actionId]) {
                            if (
                                Array.isArray(
                                    blockValues[block.condition.actionId]
                                ) &&
                                blockValues[block.condition.actionId].includes(
                                    block.condition.value
                                )
                            ) {
                                return true;
                            } else if (
                                blockValues[block.condition.actionId] ===
                                block.condition.value
                            ) {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                    return false;
                });
                setVisibleBlocks(visibleBlocks);

                //let completedBlocks =  question.ui.blocks.map(block=>{
                let completedBlocks = visibleBlocks.map((block) => {
                    let actionId = block.actionId;
                    let x = filteredValues[actionId];

                    if (Array.isArray(x) || typeof x === 'object') {
                        if (block.type === 'yes-no-list') {
                            if (
                                typeof x === 'object' &&
                                Object.keys(x).length === block.questions.length
                            ) {
                                return true;
                            }
                        } else if (x.length > 0) {
                            return true;
                        }
                    } else if (typeof x === 'string') {
                        return true;
                    }
                    return false;
                });
                setCompletedBlocks(completedBlocks || []);
            } else {
                setCompletedBlocks([]);
                setVisibleBlocks([]);
            }
        }, [assessmentId, blockValues, currentUser.id, question, questionId]);

        // automatically start infosec/demo assessment
        useEffect(() => {
            if (
                (isInfosec || isDemo) &&
                assessmentStore.assessment &&
                !isStarted &&
                !isFinished &&
                !isExpired &&
                !isTimedOut
            ) {
                onStart();
            }
        }, [
            isInfosec,
            isStarted,
            isExpired,
            isFinished,
            isDemo,
            assessmentStore.assessment,
            onStart,
            isTimedOut,
        ]);

        useEffect(() => {
            //If this question has a fullscreen presentation, then there are no blocks,
            // so it automatically is set as complete ('next' goes to next lesson not next block)
            if (question && question.ui.presentation.fullScreen) {
                enableAssessmentFinish();
            } else if (question && completedBlocks && visibleBlocks.length) {
                setQuestionComplete(
                    visibleBlocks.length ===
                        completedBlocks.filter((x) => x).length
                );
            } else setQuestionComplete(false);
        }, [
            questionId,
            completedBlocks,
            question,
            enableAssessmentFinish,
            visibleBlocks,
        ]);

        useEffect(() => {
            if (question && blockNum > -1 && blockValues) {
                let currentBlock = visibleBlocks && visibleBlocks[blockNum];

                if (currentBlock) {
                    let actionId = currentBlock.actionId;
                    switchContinueButton(blockValues[actionId]);
                }
            } else {
                setQuestionComplete(false);
            }
        }, [
            question,
            blockNum,
            blockValues,
            companyId,
            visibleBlocks,
            switchContinueButton,
        ]);

        useEffect(() => {
            (async () => {
                if (companyId && questionId) {
                    await questionStore.loadQuestion(
                        companyId,
                        questionId,
                        assessmentId
                    );

                    let _questionActions = [];
                    let _blockValues = {};
                    let _blockNum;
                    try {
                        let v = window.localStorage.getItem(
                            `question-actions-${
                                currentUser.id || currentUser.uid
                            }-${questionId}-${assessmentId}`
                        );
                        if (v) _questionActions = JSON.parse(v);
                    } catch (e) {
                        _questionActions = [];
                    }
                    try {
                        let v = window.localStorage.getItem(
                            `block-values-${
                                currentUser.id || currentUser.uid
                            }-${questionId}-${assessmentId}`
                        );
                        if (v) _blockValues = JSON.parse(v);
                    } catch (e) {
                        _blockValues = {};
                    }
                    _blockNum =
                        parseInt(
                            window.localStorage.getItem(
                                `block-num-${
                                    currentUser.id || currentUser.uid
                                }-${questionId}-${assessmentId}`
                            )
                        ) || 0;
                    setBlockNum(_blockNum);
                    setBlockValues(filterBlockValues(_blockValues));
                    setQuestionActions(_questionActions);
                }
            })();
        }, [
            questionId,
            companyId,
            questionStore,
            assessmentId,
            currentUser.id,
            currentUser.uid,
        ]);

        const getActualBlockCount = useCallback(() => {
            if (!question) return;
            return Math.max(
                visibleBlocks ? visibleBlocks.length : 0,
                question.ui.minimumBlockCount || 0
            );
        }, [question, visibleBlocks]);

        const switchContinueButton = useCallback(
            (blockValue) => {
                if (
                    Array.isArray(blockValue) ||
                    typeof blockValue === 'object'
                ) {
                    if (
                        visibleBlocks[blockNum] &&
                        visibleBlocks[blockNum].type === 'yes-no-list'
                    ) {
                        if (
                            typeof blockValue === 'object' &&
                            Object.keys(blockValue).length ===
                                visibleBlocks[blockNum].questions.length
                        ) {
                            onQuestionDone(true);
                        }
                    } else if (
                        visibleBlocks[blockNum] &&
                        visibleBlocks[blockNum].type === 'decision'
                    ) {
                        //onQuestionDone(finishedDecisionTree)
                    } else if (
                        visibleBlocks[blockNum] &&
                        visibleBlocks[blockNum].type === 'groupOfBlocks'
                    ) {
                        onQuestionDone(
                            blockValue && allBlocksHaveValue(blockValue)
                        );
                    } else {
                        onQuestionDone(blockValue && blockValue.length > 0);
                    }
                } else {
                    onQuestionDone(!!blockValue);
                }
            },
            [allBlocksHaveValue, blockNum, visibleBlocks]
        );

        /** Returns true if the given groupValue has a value for each block within it, that is not the empty string
         * Example of such groupValue:
         * [
         *   {
         *     "check_for_updates": "check_manually"
         *   },
         *   {
         *      "install_updates": ""
         *   },
         *   {
         *     "updates_from_websites": "automatically"
         *   }
         * ]
         *
         * */
        const allBlocksHaveValue = useCallback(
            (groupValue) => {
                return (
                    visibleBlocks[blockNum] &&
                    visibleBlocks[blockNum].group &&
                    groupValue.length ===
                        visibleBlocks[blockNum].group.length &&
                    !groupValue.find(
                        (valueObject) => Object.values(valueObject)[0] === ''
                    )
                );
            },
            [blockNum, visibleBlocks]
        );

        const handleBlockChange = useCallback(
            (value, actionId, block, omitTransition = false) => {
                let blockActionId =
                    actionId || visibleBlocks[blockNum].actionId;
                let updatedBlockValues = { ...blockValues };
                let newVal = {
                    ...updatedBlockValues,
                    ...{ [blockActionId]: value },
                };
                let actualBlockCount = getActualBlockCount();

                setBlockValues(filterBlockValues(newVal));
                switchContinueButton(value);

                if (
                    !omitTransition &&
                    actualBlockCount > 1 &&
                    blockNum < actualBlockCount - 1
                ) {
                    setBlockFadeIn(false);
                    setBlockFadeOut(true);
                    if (transitionTimer) {
                        try {
                            clearTimeout(transitionTimer);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    setTransitionTimer(
                        setTimeout(() => {
                            setTransitionTimer(null);
                            setBlockTransition(false);
                            setBlockTransition(true);
                        }, 500)
                    );
                }
            },
            [
                blockNum,
                blockValues,
                getActualBlockCount,
                switchContinueButton,
                transitionTimer,
                visibleBlocks,
            ]
        );

        const loadData = useCallback(async () => {
            await assessmentStore.loadAssessment(assessmentId);
        }, [assessmentId, assessmentStore, companyId]);

        useEffect(() => {
            loadData();
        }, [assessmentId, language, loadData]);

        const onTimeOver = useCallback(() => {
            assessmentStore.setTimedOut();
            if (isDemo) {
                commonStore.showConfirm(
                    '',
                    t('Ok'),
                    t('Time is up!'),
                    () => {},
                    false,
                    false
                );
            } else {
                commonStore.showConfirm(
                    t(
                        'Unfortunately, time is up. Your answers have been submitted.'
                    ),
                    t('Ok'),
                    t('Time is up!')
                );
            }
        }, [assessmentStore, commonStore, isDemo, t]);

        useEffect(() => {
            if (loadingAssessmentId || loadingAssessmentId == null) return;
            if (!assessment && !loadingAssessmentId) {
                if (redirectOnError) {
                    if (isInfosec) {
                        demoStore.restart();
                        authStore.forgetUser();
                        console.error('No assessment found');
                    }
                    return history.push(parentUrl);
                }
                return;
            }

            if (assessment && isTimedOut && !isFinished && isDemo) {
                onTimeOver();
            }

            setIsNextAvailable(false);
            let q =
                assessment.definition.questions[
                    Math.min(
                        assessment.definition.questions.length - 1,
                        Math.max(0, assessment.status.questionsDone.length)
                    )
                ];
            setQuestionId(q);
        }, [
            assessment,
            loadingAssessmentId,
            history,
            isDemo,
            isFinished,
            isTimedOut,
            onTimeOver,
            parentUrl,
            redirectOnError,
        ]);

        /** Get only the blockValues of visible blocks (others don't matter) **/
        const getActualBlockValues = useCallback(() => {
            const visibleBlockActionIds = visibleBlocks.map(
                (visibleBlock) => visibleBlock.actionId
            );
            const actualBlockValues = pick(blockValues, visibleBlockActionIds);
            return actualBlockValues;
        }, [blockValues, visibleBlocks]);

        const onFinish = useCallback(async () => {
            // complete last question first:
            let ok = await assessmentStore.completeQuestion(
                assessmentId,
                questionId,
                [
                    ...questionActions,
                    {
                        action: 'complete_question',
                        value: getActualBlockValues(),
                        details: {
                            isMobile: isMobile,
                            UA: getUA,
                        },
                    },
                ],
                trainingStore.training && trainingStore.training.assessment
            );
            if (ok) {
                if (handleOnFinish) {
                    handleOnFinish(questionId);
                }
                if (
                    assessment &&
                    !assessment.status.questionsDone.includes(questionId)
                )
                    assessment.status.questionsDone.push(questionId);
                delete localStorage[
                    `question-actions-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`
                ];
                delete localStorage[
                    `block-values-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`
                ];
                delete localStorage[
                    `block-num-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`
                ];
            }
            if (assessmentStore.loadAssessments)
                assessmentStore.loadAssessments();
            setQuestionId(null);
        }, [
            assessment,
            assessmentId,
            assessmentStore,
            currentUser.id,
            currentUser.uid,
            getActualBlockValues,
            questionActions,
            questionId,
            trainingStore.training,
        ]);

        const handleClosePage = useCallback(() => {
            if (isInfosec) {
                history.push(`/hra-demo/finish`);
            }
            if (onClose) {
                onClose();
            } else {
                history.push(parentUrl);
            }
        }, [history, isInfosec, onClose, parentUrl]);

        const onQuestionAction = useCallback(
            async (questionId, action, value) => {
                // if ( immediate ) {
                //     await assessmentStore.questionAction(assessmentId, questionId, action, value)
                // }
                // else{

                //Keeping questionActions up-to-date, but maybe we don't need this...just POST every action
                let existingQuestionValue = find(questionActions, {
                    questionId,
                    action,
                });
                if (existingQuestionValue) {
                    existingQuestionValue.value = value;
                    setQuestionActions([...questionActions]);
                } else {
                    existingQuestionValue = { questionId, action, value };
                    setQuestionActions([
                        ...questionActions,
                        existingQuestionValue,
                    ]);
                }

                //do not post  every time.  at least use immediate flag  for exact actions which should be posted right when happens
                // await assessmentStore.questionAction(assessmentId, questionId, action, value);
            },
            [questionActions]
        );

        const onNextLesson = useCallback(async () => {
            if (!assessment) return;
            const questionId =
                assessment.definition.questions[
                    assessment.status.questionsDone.length
                ];

            let ok = await assessmentStore.completeQuestion(
                assessmentId,
                questionId,
                [
                    ...questionActions,
                    {
                        action: 'complete_question',
                        value: blockValues,
                        details: {
                            isMobile: isMobile,
                            UA: getUA,
                        },
                    },
                ],
                trainingStore.training && trainingStore.training.assessment
            );
            if (ok) {
                if (handleOnQuestionComplete) {
                    handleOnQuestionComplete(questionId);
                }
                if (!assessment.status.questionsDone) {
                    assessment.status.questionsDone = [];
                }
                if (!assessment.status.questionsDone.includes(questionId))
                    assessment.status.questionsDone.push(questionId);

                delete localStorage[
                    `question-actions-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`
                ];
                delete localStorage[
                    `block-values-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`
                ];
                delete localStorage[
                    `block-num-${
                        currentUser.id || currentUser.uid
                    }-${questionId}-${assessmentId}`
                ];

                setQuestionId(
                    assessment.definition.questions[
                        assessment.status.questionsDone.length
                    ]
                );
                setBlockNum(0);
                setQuestionActions([]);
                setBlockValues({});
            }
        }, [
            assessment,
            assessmentId,
            assessmentStore,
            blockValues,
            currentUser.id,
            currentUser.uid,
            questionActions,
            trainingStore.training,
        ]);

        const onNextBlock = useCallback(async () => {
            let newBlockNum = Math.min(blockNum + 1, visibleBlocks.length - 1);
            setBlockNum(newBlockNum);
            let block = visibleBlocks[newBlockNum];
            let blockActionId = block.actionId;
            handleBlockChange(
                blockValues[blockActionId],
                blockActionId,
                block,
                true
            );
        }, [blockNum, blockValues, handleBlockChange, visibleBlocks]);

        const onPrevBlock = useCallback(async () => {
            let newBlockNum = Math.max(blockNum - 1, 0);
            setBlockNum(newBlockNum);
            //let  block = question.ui.blocks[newBlockNum]
            let block = visibleBlocks[newBlockNum];
            let blockActionId = block.actionId;
            handleBlockChange(
                blockValues[blockActionId],
                blockActionId,
                block,
                true
            );
        }, [blockNum, blockValues, handleBlockChange, visibleBlocks]);

        /* Call this on each answer. Will call next block, next lesson or finish assessment based on the assessment state*/
        const onNext = useCallback(
            async (e, isTransition = false) => {
                if (!isLastStep()) {
                    if (!question) return;
                    if (questionComplete && !isTransition)
                        return onNextLesson();

                    return onNextBlock();
                } else {
                    return await onFinish();
                }
            },
            [
                isLastStep,
                onFinish,
                onNextBlock,
                onNextLesson,
                question,
                questionComplete,
            ]
        );

        const isLastStep = useCallback(() => {
            return (
                assessment &&
                assessment.status &&
                assessment.status.questionsDone.length ===
                    assessment.definition.questions.length - 1 &&
                questionComplete
            );
        }, [assessment, questionComplete]);

        /* Called to set the current question to 'Finish' state, meaning it can be completed (the 'Finish/Next' button enabled) */
        const enableAssessmentFinish = useCallback(() => {
            setQuestionComplete(true);
            onQuestionDone(true);
        }, []);

        const onQuestionDone = (nextAvailable) => {
            setIsNextAvailable(nextAvailable);
        };

        const ModalCountdown = useCallback(() => {
            if (!assessment) return;
            return (
                <Countdown
                    date={assessment.end}
                    onComplete={onTimeOver}
                    renderer={renderModalTimeLeft}
                />
            );
        }, [assessment, onTimeOver, renderModalTimeLeft]);

        const closeAssessment = useCallback(() => {
            if (!assessment) return;
            if (isTimedOut || !isStarted || isFinished) {
                if (isInfosec) {
                    demoStore.restart();
                    authStore.logout();
                }
                history.push(parentUrl);
            } else {
                commonStore.showConfirm(
                    <>
                        {`${t('assCloseModalDescription')}${' '}`}
                        <Trans
                            components={{
                                Timer: isTimedOut ? (
                                    <span className="error">00:00</span>
                                ) : (
                                    <ModalCountdown />
                                ),
                            }}
                        >
                            {assessment.end && t('assCloseModalTimer')}
                        </Trans>
                    </>,
                    t('assCloseModalButton'),
                    t('assCloseModalTitle'),
                    () => {
                        if (isInfosec) {
                            demoStore.restart();
                            authStore.logout();
                        }
                        history.push(parentUrl);
                    },
                    false,
                    true,
                    false,
                    t('cancel')
                );
            }
        }, [
            ModalCountdown,
            assessment,
            commonStore,
            history,
            isFinished,
            isStarted,
            isTimedOut,
            parentUrl,
            t,
            authStore,
            demoStore,
            isInfosec,
        ]);

        const onStart = useCallback(async () => {
            await assessmentStore.startAssessment(assessmentId, assessment);
        }, [assessment, assessmentId, assessmentStore]);

        const renderTimeLeft = (props) => {
            let secondsLeft =
                props.hours * 60 * 60 + props.minutes * 60 + props.seconds;
            if (props.hours > 0)
                return `${props.hours} ${t(pluralize('hour', props.hours))} ${t(
                    'left'
                )}`;
            if (secondsLeft < 300)
                return `${props.hours
                    .toString()
                    .padStart(2, '0')}:${props.minutes
                    .toString()
                    .padStart(2, '0')}:${props.seconds
                    .toString()
                    .padStart(2, '0')}`;
            return `${(props.minutes + 1).toString()} ${t(
                pluralize('minute', props.minutes)
            )} ${t('left')}`;
        };

        const renderModalTimeLeft = useCallback(
            (props) => {
                let secondsLeft =
                    props.hours * 60 * 60 + props.minutes * 60 + props.seconds;
                if (props.hours > 0) {
                    return (
                        <span>{`${props.hours} ${t(
                            pluralize('hour', props.hours)
                        )}`}</span>
                    );
                } else if (secondsLeft < 300) {
                    return (
                        <span
                            className={classnames({ error: secondsLeft < 120 })}
                        >
                            {props.hours.toString().padStart(2, '0') +
                                ':' +
                                props.minutes.toString().padStart(2, '0') +
                                ':' +
                                props.seconds.toString().padStart(2, '0')}
                        </span>
                    );
                } else {
                    return (
                        <span>{`${(props.minutes + 1).toString()} ${t(
                            pluralize('minute', props.minutes)
                        )}`}</span>
                    );
                }
            },
            [t]
        );

        const assessmentName = useMemo(() => {
            if (!assessment) return;
            if (isDemo) return 'Human Risk Assessment';
            return assessment.name;
        }, [assessment, isDemo]);

        const body = useMemo(() => {
            if (isExpired)
                return (
                    <FlexDiv
                        alignCenter
                        padding={40}
                        gap={8}
                        $noWrap
                        fullHeight
                    >
                        <BlockIcon fontSize="large" />
                        <Heading2>{t('This assessment has expired.')}</Heading2>
                    </FlexDiv>
                );
            if (!isStarted && !isInfosec && !isDemo)
                return (
                    <div className="wizard-body scroll">
                        <QuizIntro onStart={onStart} assessment={assessment} />
                    </div>
                );
            if (isFinished)
                return (
                    <div className="wizard-body scroll">
                        <Review
                            parentUrl={parentUrl}
                            assessmentStore={assessmentStore}
                            assessment={assessment}
                            assessmentId={assessmentId}
                            companyId={companyId}
                            onShowResults={onShowResults}
                            preloadedResults={assessment && assessment.results}
                            language={store.language}
                            bannerImgSrc={
                                assessment && assessment.bannerImageSrc
                            }
                            bannerLinkURL={
                                assessment && assessment.bannerLinkURL
                            }
                            bannerSnippet={
                                assessment && assessment.bannerSnippet
                            }
                        />
                    </div>
                );
            return (
                <div className="wizard-body">
                    <div className={'wizard-content wide'}>
                        <ErrorMessage error={questionError} />
                        <Question
                            handleBlockChange={handleBlockChange}
                            loadingData={loadingQuestionData}
                            question={question}
                            blockNum={blockNum}
                            onPrevBlock={onPrevBlock}
                            onNextBlock={onNextBlock}
                            blockValues={blockValues}
                            questionId={questionId}
                            assessmentId={assessmentId}
                            completedBlocks={completedBlocks}
                            visibleBlocks={visibleBlocks}
                            userId={currentUser.id}
                            userName={currentUser.name}
                            userEmail={currentUser.email}
                            language={store.language}
                            onQuestionAction={onQuestionAction}
                            enableAssessmentFinish={enableAssessmentFinish}
                            onNextQuestion={onNext}
                            blockFadeIn={blockFadeIn}
                            blockFadeOut={blockFadeOut}
                        />
                    </div>
                </div>
            );
        }, [
            assessment,
            assessmentId,
            assessmentStore,
            blockFadeIn,
            blockFadeOut,
            blockNum,
            blockValues,
            companyId,
            completedBlocks,
            currentUser.email,
            currentUser.id,
            currentUser.uid,
            currentUser.name,
            enableAssessmentFinish,
            handleBlockChange,
            isDemo,
            isExpired,
            isFinished,
            isInfosec,
            isStarted,
            loadingQuestionData,
            onNext,
            onNextBlock,
            onPrevBlock,
            onQuestionAction,
            onStart,
            parentUrl,
            question,
            questionError,
            questionId,
            store.language,
            t,
            visibleBlocks,
        ]);

        if (loadingData || loadingAssessmentId || !assessment) {
            return (
                <>
                    {assessmentError ? (
                        <ErrorMessage error={assessmentError} />
                    ) : (
                        <OneMomentSpinner />
                    )}
                </>
            );
        }

        return (
            <div className={classnames('wizard')}>
                <ViewHeader
                    onClose={closeAssessment}
                    isDemo={isDemo}
                    isInfosec={isInfosec}
                    brandingLoaded={brandingLoaded}
                    logo={logo}
                    account_name={account_name}
                    isFinished={isFinished}
                    isStarted={isStarted}
                    done={done}
                    mobileMainHeading={
                        !isStarted || isFinished ? (
                            <Heading3>{assessmentName}</Heading3>
                        ) : (
                            <Heading4>{assessmentName}</Heading4>
                        )
                    }
                    mobileSubheading={
                        !isFinished &&
                        isStarted && (
                            <FlexDiv alignEnd gap={4}>
                                <TextLabel bold>
                                    {assessment.status.questionsDone.length + 1}
                                    /{assessment.definition.questions.length}{' '}
                                    {t(
                                        pluralize(
                                            'question',
                                            assessment.definition.questions
                                                .length
                                        )
                                    )}
                                </TextLabel>
                                {isTimedOut ? (
                                    <TextLabel color={palette.vibrant.red}>
                                        00:00
                                    </TextLabel>
                                ) : (
                                    <TextLabel lighter>
                                        ·{' '}
                                        <Countdown
                                            date={assessment.end}
                                            onComplete={onTimeOver}
                                            renderer={renderTimeLeft}
                                        />
                                    </TextLabel>
                                )}
                            </FlexDiv>
                        )
                    }
                    desktopMainHeading={assessmentName}
                    mobileRightCorner={
                        <>
                            {' '}
                            {availableLanguages && !brandingStore.hideLanguageList ? (
                                <LanguageSelector
                                    selectedLanguage={selectedLanguage}
                                    handleSelectLanguage={(language) => {
                                        switchLanguage(language);
                                    }}
                                    availableLanguages={availableLanguages}
                                />
                            ) : null}
                        </>
                    }
                    desktopRightCorner={
                        <FlexDiv $noWrap alignCenter gap={16}>
                            {availableLanguages && !brandingStore.hideLanguageList ? (
                                <LanguageSelector
                                    selectedLanguage={selectedLanguage}
                                    handleSelectLanguage={(language) => {
                                        switchLanguage(language);
                                    }}
                                    availableLanguages={availableLanguages}
                                />
                            ) : null}
                            {!isFinished && isStarted && (
                                <FlexDiv column>
                                    <TextDefault bold noWrap>
                                        {assessment.status.questionsDone
                                            .length + 1}
                                        /
                                        {assessment.definition.questions.length}{' '}
                                        {t(
                                            pluralize(
                                                'question',
                                                assessment.definition.questions
                                                    .length
                                            )
                                        )}
                                    </TextDefault>
                                    {isTimedOut ? (
                                        <TextTiny
                                            color={palette.vibrant.red}
                                            noWrap
                                        >
                                            00:00
                                        </TextTiny>
                                    ) : (
                                        <TextTiny lighter noWrap>
                                            <Countdown
                                                date={assessment.end}
                                                onComplete={onTimeOver}
                                                renderer={renderTimeLeft}
                                            />
                                        </TextTiny>
                                    )}
                                </FlexDiv>
                            )}
                        </FlexDiv>
                    }
                />
                {body}
                <AssessmentsViewFooter
                    isExpired={isExpired}
                    isStarted={isStarted}
                    isTimedOut={isTimedOut}
                    onStart={onStart}
                    skipStart={isInfosec}
                    isFinished={isFinished}
                    isDemo={isDemo}
                    restartDemo={restartDemo}
                    isNextAvailable={isNextAvailable}
                    actionInProgress={_questionAction}
                    onNext={onNext}
                    isLastStep={isLastStep}
                    handleClosePage={handleClosePage}
                />
            </div>
        );
    }
);
