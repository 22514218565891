import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    formatDateAndTime,
    formatDurationToTime,
    formatDurationToHumanReadableString,
} from '../../utils/helpers';
import {
    Heading1,
    Heading4,
    Heading3,
    FlexDiv,
    FiftyFiftyDiv,
    TextDefault,
    ThemedButton,
    FocusArea,
} from '@awarego/awarego-components';
import ThemedAssessmentIntroSVG from '../themed/ThemedAssessmentIntroSVG';
import { useStores } from '../../hooks/use-stores';

export default ({ onStart, assessment }) => {
    const { t, i18n } = useTranslation();

    const { brandingStore } = useStores();

    /** Getting optional replacement intro text from settings depending on user language, defaults to english */
    function getLocalizedIntroText(settings, languageCode) {
        // Define the default key
        const defaultKey = 'ui.assessments.intro_text';

        // Try to get the string with the specified language code
        let introText = settings[`${defaultKey}.${languageCode}`];

        // If not found, try to get the string with the 'en' language code
        if (!introText) {
            introText = settings[`${defaultKey}.en`];
        }

        // If still not found, use the default string
        if (!introText) {
            introText = settings[defaultKey];
        }

        return introText;
    }

    const replacementIntroText = getLocalizedIntroText(
        brandingStore && brandingStore.branding,
        i18n.language
    );
    const leftHalfContent = useMemo(() => {
        return (
            <>
                <Heading1 displayBlock>{assessment.name}</Heading1>
                <Heading3>
                    {assessment.definition.questions.length}{' '}
                    {t(
                        pluralize(
                            'question',
                            assessment.definition.questions.length
                        )
                    )}{' '}
                </Heading3>
                {assessment.due && (
                    <Heading4 mobileOnly>
                        <i className="date-icon due"></i>
                        {t('Due on')} {formatDateAndTime(assessment.due)}{' '}
                        {t('GMT')}
                    </Heading4>
                )}

                {assessment.description && (
                    <Heading3 displayBlock>{assessment.description}</Heading3>
                )}
                {assessment.objective && (
                    <Heading3 displayBlock>{assessment.objective}</Heading3>
                )}
                <TextDefault style={{ whiteSpace: 'pre-wrap' }}>
                    {replacementIntroText ? (
                        replacementIntroText
                    ) : (
                        <Trans
                            components={{
                                b: <strong />,
                            }}
                        >
                            {t('assessment_intro_message_part_1')}
                        </Trans>
                    )}
                </TextDefault>
                <FocusArea
                    iconSrc="/static/img/icon-stopwatch.svg"
                    iconAltText={t('Time to complete')}
                    headingText={formatDurationToTime(assessment.duration)}
                    text={t('assessment_intro_time_to_complete', {
                        time: formatDurationToHumanReadableString(
                            assessment.duration
                        ),
                        interpolation: { escapeValue: false },
                    })}
                />
                {assessment.due && (
                    <TextDefault>
                        <Trans
                            components={{
                                b: <strong />,
                            }}
                        >
                            {t('assessment_intro_message_part_2', {
                                assessmentDueDate: formatDateAndTime(
                                    assessment.due
                                ),
                            })}
                        </Trans>
                    </TextDefault>
                )}

                <FlexDiv desktopOnly>
                    <ThemedButton
                        mode="primary"
                        onClick={onStart}
                        text={t('Start now')}
                    ></ThemedButton>
                </FlexDiv>
            </>
        );
    }, [assessment, t]);

    const rightHalf = useMemo(() => {
        return (
            <FlexDiv justifyCenter fullWidth>
                <ThemedAssessmentIntroSVG
                    primaryColor={brandingStore.theme.primary}
                />
            </FlexDiv>
        );
    }, [brandingStore]);

    return assessment != null ? (
        <FiftyFiftyDiv
            leftHalf={
                <>
                    {/* Needed to get the marginLeft for desktop only without adding more props to flexDiv */}
                    <FlexDiv column gap={16} mobileOnly>
                        {leftHalfContent}
                    </FlexDiv>
                    <FlexDiv column ml={64} gap={16} desktopOnly>
                        {leftHalfContent}
                    </FlexDiv>
                </>
            }
            rightHalf={rightHalf}
            stackOnMobile={true}
        />
    ) : null;
};
