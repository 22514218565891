import { reaction, makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import uuid from 'uuid/v4';
import { generateCreateEntity, generateLoadEntity } from '../utils/mobx';
import subjectStore from './models/subjectStore';
import { extractErrorMessage } from '../utils/helpers';
import defaults from 'lodash/defaults';
import { EVENTS } from '../utils/constants';
import Events from './events';
import i18n from 'i18next';
import { addBeforeUnloadReactionToAssessmentStore } from './mixins/beforeUnload';
import * as Sentry from '@sentry/react';
import keyBy from 'lodash/keyBy';
import every from 'lodash/every';
import moment from 'moment/moment';
import { flatten, mean } from 'lodash';
import contentDisposition from 'content-disposition';

class CMI5Store {
    loadingData = false;
    loadingAssessmentResults = false;
    _questionAction = false;
    loadingAssessmentId = null;
    loadingTrainingId = null;
    startingAssessmentId = null;
    error = null;
    videoTokenError = null;
    assessments = [];
    results = null;
    assessmentAnswers = {};
    assessment = null;
    training = null;
    lastVideoId = null;

    // subjects = [];
    statusUpdate = 0;
    reviewingQuestions = [];
    cmi = null;
    rxd = null;
    cmiUserUID = null;
    rxdRegistration = null;
    question = null;
    loadingQuestion = false;
    scormPackage = null;
    loadingPackage = null;
    sessionEvents = {};
    loadingTokens = [];
    tokens = [];

    constructor(authStore, companyStore, mainStore, commonStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
        this.companyStore = companyStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;
        this.mainStore.cmiStore = this; //backlink

        reaction(
            () => ({
                isCompleted: this.isCompleted,
                score: this.assessment && this.assessment.averageScore,
            }),
            ({ isCompleted, score }) => {
                if (isCompleted && score != null) {
                    if (this.rxd) {
                        this.rxd.SetScore(score, 100, 0);
                        this.rxd.SetReachedEnd();
                        if (score >= 50) {
                            this.rxd.SetPassed();
                        } else this.rxd.SetFailed();
                    }
                }
            }
        );

        addBeforeUnloadReactionToAssessmentStore(this);
        reaction(
            () => this.certificateQualified,
            (certificateQualified) => {
                if (certificateQualified) {
                    if (this.rxd) {
                        const score = Math.round(
                            (100 * this.subjectsDone.length) /
                                this.subjects.length,
                            2
                        );
                        this.rxd.SetScore(score, 100, 0);
                        this.rxd.SetReachedEnd();
                        this.rxd.SetPassed();
                    }
                }
            }
        );
        reaction(
            () => this.subjectsDone,
            (subjectsDone) => {
                if (subjectsDone.length > 0) {
                    const score = Math.round(
                        (100 * subjectsDone.length) / this.subjects.length,
                        2
                    );
                    this.rxd.SetScore(score, 100, 0);
                }
            }
        );
        reaction(
            () => this.language,
            () => {
                //   this.tokens.replace([]);
            }
        );
    }

    setCMI(value) {
        this.cmi = value;
        this.cmiUserUID = uuid();
    }

    setRXD(value) {
        this.rxd = value;
        this.cmiUserUID = uuid();
        this.rxdRegistration = this.rxd.GetSuspendData();
        if (!this.rxdRegistration) {
            this.rxdRegistration = uuid();
            this.rxd.SetSuspendData(this.rxdRegistration);
        }
    }

    get i18n() {
        return this.mainStore.i18n;
    }

    get language() {
        let defaultLanguage = this.mainStore.language;
        if( this.scormPackage && !this.scormPackage.availableLanguages.includes(defaultLanguage)) {
            defaultLanguage = this.scormPackage.availableLanguages[0];
            this.setLanguage(defaultLanguage);
        }
        return defaultLanguage;
    }

    async setLanguage(language_code) {
        this.mainStore.setLanguage(language_code, true);
        this.tokens.replace([]);
    }

    setCompanyId(value) {
        this.companyId = value;
    }

    setContext(value) {
        this.context = value;
    }

    logout = () => {
        this.assessments.replace([]);
        this.loadingData = false;
    };

    setError = (error, type) => {
        if (type === 'videoToken') this.videoTokenError = error;
        else this.error = error;
        this.commonStore.error(error);
    };

    get cmiUser() {
        if (this.rxd) {
            return {
                id: this.cmiUserUID,
                actor: {
                    id: this.rxd.GetStudentID(),
                    name: this.rxd.GetStudentName(),
                },
                name: this.rxd.GetStudentName(),
                extId: this.rxd.GetStudentID(),
            };
        }
        return {
            id: this.cmiUserUID,
            actor: this.cmi && this.cmi.getLaunchParameters().actor,
        };
    }

    get cmiRegistration() {
        if (this.rxd) {
            return this.rxdRegistration;
        }
        return this.cmi && this.cmi.getLaunchParameters().registration;
    }

    get cmiRequestParamForSocket() {
        return {
            ...this.context,
            companyId: this.companyId,
            l: this.mainStore.language,
            registration: this.cmiRegistration,
        };
    }

    get cmiRequestParams() {
        return {
            ...this.context,
            user: this.cmiUser,
            l: this.mainStore.language,
            registration: this.cmiRegistration,
        };
    }

    get isCompleted() {
        return this.assessment && !!this.assessment.completed;
    }

    loadPackage = generateLoadEntity(
        'package',
        this,
        'loadingPackage',
        async (companyId, uniqueId) => {
            return services.Companies.cmi5Service(companyId).package(
                uniqueId,
                this.cmiRequestParams
            );
        },
        'scormPackage'
    );

    async loadAssessment(uniqueId, background) {
        if (!background) {
            if (this.loadingAssessmentId === uniqueId) return;
            this.loadingAssessmentId = uniqueId;
        }
        this.setError(null);
        try {
            let data = await services.Companies.cmi5Service(
                this.companyId
            ).myAssessment(uniqueId, {
                ...this.cmiRequestParams,
                l: this.mainStore.language,
            });
            this.assessment = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingAssessmentId = false;
        }
    }

    shareUrl() {
        return null;
    }

    async startAssessment(id, targetObj) {
        if (this.startingAssessmentId === id) return;
        this.startingAssessmentId = id;

        this.setError(null);
        try {
            let data = await services.Companies.cmi5Service(
                this.companyId
            ).startAssessment(id, this.cmiRequestParams);
            if (data) Object.assign(targetObj || this.assessment, data);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.startingAssessmentId = null;
        }
    }

    async loadTraining(id, background) {
        if (!background) {
            if (this.loadingTrainingId) return;
            this.loadingTrainingId = true;
        }
        this.setError(null);
        try {
            let data = await services.Companies.cmi5Service(
                this.companyId
            ).myTraining(id, {
                ...this.cmiRequestParams,
                l: this.language,
            });
            if (data.subjects) {
                let subjects = [];
                for (const subject of data.subjects) {
                    if (subject.type === 3) {
                        subjects.push(new subjectStore(subject));
                    } else subjects.push(subject);
                }
                data.subjects = subjects;
            }
            this.training = data;
            //this.subjects.replace(data.subjects);
            this.mainStore.setLayoutTitle(data.name);
        } catch (e) {
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingTrainingId = null;
        }
    }

    async reloadTraining() {
        this.training && this.loadTraining(this.training.id, true);
    }

    loadQuestion = generateLoadEntity(
        'question',
        this,
        'loadingQuestion',
        async (companyId, id, assessmentId) => {
            return services.Companies.cmi5Service(
                companyId
            ).getPersonalizedQuestion(id, assessmentId, this.cmiRequestParams);
        },
        'question'
    );

    loadResults = generateLoadEntity(
        'results',
        this,
        'loadingAssessmentResults',
        async (companyId, id) => {
            const result = await services.Companies.cmi5Service(
                companyId
            ).results(id, this.cmiRequestParams);
            if (this.assessment && !this.assessment.averageScore && result) {
                runInAction(() => {
                    this.assessment.averageScore = mean(
                        Object.values(result).map((x) => x.averageScore)
                    );
                });
            }
            return result;
        },
        'results'
    );

    loadAnswers = generateLoadEntity(
        'assessmentAnswers',
        this,
        'loadingAssessmentResults',
        async (companyId, id) => {
            return services.Companies.cmi5Service(companyId).answers(
                id,
                this.cmiRequestParams
            );
        },
        'assessmentAnswers'
    );

    completeQuestion = generateCreateEntity(
        'completeQuestion',
        this,
        '_questionAction',
        (assessmentId, id, questions) => {
            return services.Companies.cmi5Service(
                this.companyId
            ).questionActions(
                assessmentId,
                id,
                questions,
                this.cmiRequestParams
            );
        },
        (result, originalArguments) => {
            if (result) {
                Object.assign(this.assessment, result);
                if (originalArguments.assessment) {
                    Object.assign(originalArguments.assessment, result);
                }
            }
        }
    );

    isSubjectDone(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 || subject.status.VIDEO_100 > 0)
        );
    }

    isSubjectPassed(subject) {
        return (
            subject.status.ANSWARE_CORRECT > 0 &&
            (subject.status.VIDEO_95 > 0 ||
                subject.status.VIDEO_100 > 0 ||
                subject.type === 3)
        );
    }

    isSubjectDelivered() {
        return true;
    }

    get viewedVideos() {
        return this.subjects.filter((s) => this.isSubjectDone(s)).length;
    }

    get subjectsTODO() {
        return this.subjects.filter((x) => {
            if (x.type === 3)
                return x.isSubjectDelivered() && !x.isSubjectDone();
            else return this.isSubjectDelivered(x) && !this.isSubjectDone(x);
        });
    }

    get deliveredSubjects() {
        return this.subjects.filter((x) => this.isSubjectDelivered(x));
    }

    get availableUnfinishedSubjects() {
        return this.subjects.filter(
            (x) => this.isSubjectDelivered(x) && !this.isSubjectPassed(x)
        );
    }

    get subjectsDone() {
        return this.subjects.filter((x) => {
            if (x.type === 3)
                return x.isSubjectDelivered() && x.isSubjectDone();
            else {
                return this.isSubjectDelivered(x) && this.isSubjectDone(x);
            }
        });
    }

    get subjects() {
        return this.training && this.training.subjects
            ? this.training.subjects
            : [];
    }

    get certificateQualified() {
        return (
            this.training &&
            every(this.subjects, this.isSubjectPassed) &&
            (!this.training.assessment_id ||
                (this.training.assessment &&
                    this.training.assessment.status &&
                    this.training.assessment.status.finished))
        );
    }

    get isStarted() {
        return (
            this.assessment &&
            (this.assessment.started || this.assessment.status.started)
        );
    }

    get done() {
        return (
            this.assessment &&
            this.assessment.status &&
            (this.assessment.status.questionsDone.length /
                this.assessment.definition.questions.length) *
                100
        );
    }

    get isFinished() {
        return (
            this.assessment &&
            this.assessment.status &&
            (this.assessment.status.finished ||
                this.assessment.status.questionsDone.length ===
                    this.assessment.definition.questions.length)
        );
    }

    get isExpired() {
        return (
            this.assessment &&
            this.assessment.status &&
            this.assessment.status.expired &&
            !this.assessment.status.started
        );
    }

    get isTimedOut() {
        return (
            this.assessment &&
            this.assessment.status &&
            this.assessment.status.timeLeft <= 0
        );
    }

    setTimedOut() {
        this.assessment.status.timeLeft = 0;
    }

    get answers() {
        return this.mainStore.answers;
    }
    setCurrentSubjectAnswers(subject) {
        return this.mainStore.setCurrentSubjectAnswers(subject);
    }

    async onLogEvent(trainingId, subject, event_id, context, options) {
        try {
            options = defaults({}, options, { send: true });
            let company_id = this.companyId;
            let subject_id = null;
            let subject_name = '';
            let company_name = '';
            //this.showSubjectDoneNotification(event_id);

            if (subject && typeof subject === 'object') {
                subject_id = subject.id;
                subject_name = subject.title;
            } else subject_id = subject;

            const alwaysLog = [EVENTS.ANSWARE_INCORRECT].includes(event_id);
            const eventKey =
                context && context.decision
                    ? `${company_id}:demo:${subject_id}:${event_id}:${context.decision}:`
                    : `${company_id}:demo:${subject_id}:${event_id}:`;

            if (options.send && (alwaysLog || !this.sessionEvents[eventKey])) {
                const response = await services.Companies.cmi5Service(
                    company_id
                )
                    .eventsService()
                    .create({
                        event_id,
                        trainingId,
                        registration: this.rxdRegistration,
                        company_id,
                        subject_id,
                        context,
                    });
                if (
                    response &&
                    response.registeredEvents &&
                    response.registeredEvents.length > 0 &&
                    trainingId === this.training.id
                ) {
                    const subject = this.subjects.find(
                        (x) => x.id === subject_id
                    );
                    if (subject) {
                        if (response.registeredEvents.includes(5))
                            subject.status.ANSWARE_CORRECT = 1;
                        if (response.registeredEvents.includes(12))
                            subject.status.VIDEO_100 = 1;
                    }
                }

                await Events.callGoogleAnalytics(
                    event_id,
                    subject_name,
                    company_name,
                    {
                        ...context,
                        isDemo: true,
                    }
                );
                this.sessionEvents[eventKey] = true;
            }
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }
    }

    async downloadCertificate(trainingId, name) {
        this.certificateError = null;
        this.creatingCertificate = true;

        try {
            const options = {
                responseType: 'blob',
            };

            let response;
            response = await services.Companies.cmi5Service(
                this.companyId
            ).downloadCertificate(
                trainingId,
                {
                    ...this.cmiRequestParams,
                    name,
                },
                options
            );

            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                })
            );
            let fileName = `certificate.pdf`;
            const disposition =
                response.headers['content-disposition'] &&
                contentDisposition.parse(
                    response.headers['content-disposition']
                );
            if (
                disposition &&
                disposition.type === 'attachment' &&
                disposition.parameters &&
                disposition.parameters.filename
            ) {
                fileName = disposition.parameters.filename;
            }

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            let err = extractErrorMessage(e);
            this.certificateError = err;
        } finally {
            this.creatingCertificate = false;
        }
    }

    async videoToken(videoId, lang) {
        if (this.loadingTokens.includes(videoId)) return false;

        this.lastVideoId = videoId;

        this.loadingTokens.push(videoId);
        try {
            let token = await services.Companies.cmi5Service(
                this.companyId
            ).getVideoToken(this.training.id, {
                videoId,
                ...this.cmiRequestParams,
                lang: lang || this.language,
                l: lang || this.language,
            }); //videoId

            runInAction(() => {
                let t = this.tokens.find((x) => x.id === videoId);
                if (t) this.tokens.remove(t);
                this.tokens.push({
                    id: videoId,
                    token,
                    lang: lang || this.language,
                });
            });
        } catch (e) {
            Sentry.captureException(e);
            this.setError(extractErrorMessage(e), 'videoToken');
        } finally {
            this.loadingTokens.remove(videoId);
        }
    }

    get tokenIndex() {
        return keyBy(this.tokens, (x) => `${x.id}-${x.lang}`);
    }

    getTokenKey(subjectId, language) {
        return `${subjectId}-${language}`;
    }

    get resultsCount() {
        const results = this.assessment.results || this.results;
        return (
            results &&
            flatten(Object.values(results).map((r) => r.questions)).length
        );
    }
}

export default CMI5Store;
