import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router';
import InfosecAssessment from './assessment';
import InfosecAuth from './auth';
import InfosecJoin from './join';
import InfosecFinish from './finish';
import { joinPath, querystring } from '../../utils/helpers';
import AssessmentsAnswerView from '../Assessments/AssessmentsAnswerView';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { useCmi5 } from '../../hooks/use-cmi5';
// eslint-disable-next-line no-unused-vars
import AssessmentResults from '../Assessments/AssessmentResults';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { Box } from '@material-ui/core';

function InfosecHome({ variant = 'infosec' }) {
    const match = useRouteMatch();
    const { miniHRAKey } = useParams();
    const { authStore, assessmentStore, questionStore, brandingStore } =
        useStores();
    const { miniHRAOptionsLoading, miniHRAOptions } = assessmentStore;
    const { isAuthenticated, currentUser } = authStore;
    const { brandingLoaded, emailOptions } = brandingStore;
    const [isCmi5Initialized, setIsCmi5Initialized] = useState();
    const [initializationError, setInitializationError] = useState();
    const [abEmailTestOption, setAbEmailTestOption] = useState();

    const effectiveVariant = useMemo(() => {
        if (miniHRAOptions) return miniHRAOptions.id;
        return variant;
    }, [miniHRAOptions, variant]);

    const cmi5 = useCmi5();
    useEffect(() => {
        return () => {
            assessmentStore.resetMiniHRAOptionsLoading();
        };
    }, []);

    useEffect(() => {
        if (miniHRAKey) {
            assessmentStore.loadMiniHRAOptions(miniHRAKey);
        }
    }, [miniHRAKey]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!brandingLoaded) return;
        let emailOption =
            (currentUser && currentUser.emailOption) ||
            emailOptions['default'] ||
            'required-email';
        if (emailOptions[variant]) {
            emailOption = emailOptions[variant];
        } else if (variant === 'ai-challenge') {
            emailOption = Math.random() > 0.5 ? 'no-email' : 'optional-email';
        }

        console.log('emailOption: ', emailOption);

        setAbEmailTestOption(emailOption);
        initCMI5();
    }, [emailOptions]);

    useEffect(() => {
        if (
            authStore.currentUser &&
            authStore.currentUser.variant !== effectiveVariant &&
            (!miniHRAKey ||
                (!miniHRAOptionsLoading && miniHRAOptionsLoading != null))
        ) {
            authStore.forgetUser();
        }
    }, [effectiveVariant]);

    const initCMI5 = () => {
        if (cmi5) {
            authStore.forgetUser();
            cmi5.initialize()
                .then(() => {
                    setIsCmi5Initialized(cmi5.isAuthenticated);
                })
                .catch((e) => {
                    setInitializationError(e);
                });
        }
    };
    if (cmi5) {
        if (initializationError) return initializationError.message;
        if (!isCmi5Initialized) return 'Initializing';
    }

    if (
        miniHRAKey &&
        (miniHRAOptionsLoading || miniHRAOptionsLoading == null)
    ) {
        return (
            <Box
                width="100%"
                height={'100vh'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <ThemedSpinner />
            </Box>
        );
    }

    return (
        <Switch>
            <Route
                exact
                path={match.url}
                render={() => {
                    if (isAuthenticated)
                        return (
                            <Redirect
                                to={`${joinPath(match.url, 'assessment')}`}
                            />
                        );
                    else
                        return (
                            <InfosecAuth
                                variant={effectiveVariant}
                                parentMatch={match}
                                emailOption={
                                    miniHRAOptions
                                        ? miniHRAOptions.authentication
                                        : abEmailTestOption
                                }
                            />
                        );
                }}
            />
            <Route
                path={`${joinPath(match.url, 'join')}`}
                render={() => <InfosecJoin parentMatch={match} />}
            />
            <Route
                render={() => {
                    if (!isAuthenticated) return <Redirect to={match.url} />;
                    else
                        return (
                            <Switch>
                                <Route
                                    path={`${joinPath(
                                        match.url,
                                        'assessment/answers'
                                    )}`}
                                    render={() => {
                                        let questionIndex =
                                            querystring('index');
                                        return (
                                            <>
                                                {' '}
                                                <AssessmentsAnswerView
                                                    companyId={effectiveVariant}
                                                    assessmentId={
                                                        effectiveVariant
                                                    }
                                                    assessmentStore={
                                                        assessmentStore
                                                    }
                                                    questionStore={
                                                        questionStore
                                                    }
                                                    currentUser={currentUser}
                                                    parentUrl={match.url}
                                                    index={questionIndex}
                                                />
                                            </>
                                        );
                                    }}
                                />

                                <Route
                                    path={`${joinPath(
                                        match.url,
                                        ':id/answers'
                                    )}`}
                                    render={() => {
                                        let questionIndex =
                                            querystring('index');
                                        return (
                                            <Redirect
                                                to={`${joinPath(
                                                    match.url,
                                                    `assessment/answers${
                                                        questionIndex
                                                            ? `?index=${questionIndex}`
                                                            : ''
                                                    }`
                                                )}`}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    path={`${joinPath(match.url, 'finish')}`}
                                    render={() => <InfosecFinish />}
                                />

                                <Route
                                    path={`${joinPath(
                                        match.url,
                                        'assessment'
                                    )}`}
                                    render={() => (
                                        <InfosecAssessment
                                            variant={effectiveVariant}
                                            parentMatch={match}
                                        />
                                    )}
                                />
                            </Switch>
                        );
                }}
            />
        </Switch>
    );
}

export default observer(InfosecHome);
